import { withTranslations } from 'src/i18n/static-generation';
import { PageMetas } from 'src/types/meta';
import NotFoundView from 'src/views/NotFound';
export const getStaticProps = withTranslations<PageMetas>(async () => {
  return {
    props: {
      noindex: true
    }
  };
});
export default NotFoundView;